<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout align-center class="pointer" row @click="selectProject">
		<w-flex :xs4="itemWidth >= 600" :xs8="itemWidth < 600">
			<w-layout align-center row>
				<w-flex shrink>
					<w-tooltip bottom>
						<template v-slot:activator="{ on }">
							<w-icon :color="value.status.color" v-on="on">{{ value.status.icon }}</w-icon>
						</template>
						<span v-text="`projects.status.${value.status.key}`" />
					</w-tooltip>
				</w-flex>
				<w-flex grow>
					<w-layout column>
						<w-flex
							:class="{
								'font-weight-bold': isSelected,
								'primary--text': isSelected
							}"
							v-html="displayedName"
						/>
						<w-flex
							caption
							v-text="
								$t('projects.date_from_to', {
									from: $translateDateTime(value.start, 'tiny'),
									to: $translateDateTime(value.end, 'tiny')
								})
							"
						/>
					</w-layout>
				</w-flex>
			</w-layout>
		</w-flex>
		<w-flex :xs5="itemWidth >= 600" :xs2="itemWidth < 600">
			<w-layout row>
				<w-flex v-for="category in value.categories" :key="category.id" shrink>
					<LabelledCounter :color="category.color" :count="category.count" :label="category.name" :minimized="itemWidth < 600" />
				</w-flex>
			</w-layout>
		</w-flex>
		<w-flex :xs3="itemWidth >= 600" :xs2="itemWidth < 600" pr-1 text-xs-right>
			{{ $n(value.price, 'currency') }}
		</w-flex>
	</w-layout>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'ProjectItem',
	components: {
		LabelledCounter: () => ({
			component: import('@/components/Commons/LabelledCounter')
		})
	},
	mixins: [ProjectsManagerModuleGuard],
	props: {
		isSelected: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			itemWidth: null,
			resizeObserver: null
		}
	},
	computed: {
		search: {
			get: function () {
				return this.$store.state.customers.projects.filters.search
			},
			set: function (val) {
				this.$store.dispatch('customers/projects/setSearchedText', val)
			}
		},
		displayedName: function () {
			if (!this.search) {
				return this.value.name
			}
			const searchedTextRegEx = new RegExp(this.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')
			return this.value.name.replace(searchedTextRegEx, '<b>$&</b>')
		}
	},
	destroyed: function () {
		this.resizeObserver.unobserve(this.$el)
	},
	mounted: function () {
		this.resizeObserver = new ResizeObserver(entries => {
			this.itemWidth = entries[0].contentRect.width
		})
		this.resizeObserver.observe(this.$el)
	},
	methods: {
		selectProject: function () {
			return this.service.selectProject(this.value.id)
		}
	}
}
</script>
