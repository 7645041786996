var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-layout',{staticClass:"pointer",attrs:{"align-center":"","row":""},on:{"click":_vm.selectProject}},[_c('w-flex',{attrs:{"xs4":_vm.itemWidth >= 600,"xs8":_vm.itemWidth < 600}},[_c('w-layout',{attrs:{"align-center":"","row":""}},[_c('w-flex',{attrs:{"shrink":""}},[_c('w-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('w-icon',_vm._g({attrs:{"color":_vm.value.status.color}},on),[_vm._v(_vm._s(_vm.value.status.icon))])]}}])},[_c('span',{domProps:{"textContent":_vm._s(`projects.status.${_vm.value.status.key}`)}})])],1),_c('w-flex',{attrs:{"grow":""}},[_c('w-layout',{attrs:{"column":""}},[_c('w-flex',{class:{
							'font-weight-bold': _vm.isSelected,
							'primary--text': _vm.isSelected
						},domProps:{"innerHTML":_vm._s(_vm.displayedName)}}),_c('w-flex',{attrs:{"caption":""},domProps:{"textContent":_vm._s(
							_vm.$t('projects.date_from_to', {
								from: _vm.$translateDateTime(_vm.value.start, 'tiny'),
								to: _vm.$translateDateTime(_vm.value.end, 'tiny')
							})
						)}})],1)],1)],1)],1),_c('w-flex',{attrs:{"xs5":_vm.itemWidth >= 600,"xs2":_vm.itemWidth < 600}},[_c('w-layout',{attrs:{"row":""}},_vm._l((_vm.value.categories),function(category){return _c('w-flex',{key:category.id,attrs:{"shrink":""}},[_c('LabelledCounter',{attrs:{"color":category.color,"count":category.count,"label":category.name,"minimized":_vm.itemWidth < 600}})],1)}),1)],1),_c('w-flex',{attrs:{"xs3":_vm.itemWidth >= 600,"xs2":_vm.itemWidth < 600,"pr-1":"","text-xs-right":""}},[_vm._v(" "+_vm._s(_vm.$n(_vm.value.price, 'currency'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }